import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const defVals = {
  pages: {},
};

// reducer
const KVArray2obj = (resultingObject, arrayElement) => {
  const { key, value } = arrayElement;
  if (key && value) {
    resultingObject[key] = value;
  }
  return resultingObject;
};

export const PagesContext = React.createContext(defVals);

export const PagesProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query staticPages {
      sp: allStoryblokEntry(
        filter: { field_component: { eq: "Static Page" } }
      ) {
        nodes {
          content
          lang
        }
      }
    }
  `);

  const pages = data.sp.nodes.reduce((obj, node) => {
    const l = node.lang === "default" ? "it" : node.lang;
    obj[l] = obj[l] || {};
    const pageInfo = JSON.parse(node?.content || {})?.page_information[0];
    if (pageInfo && pageInfo?.page_name) {
      obj[l][pageInfo.page_name.trim()] = pageInfo;
    }
    return obj;
  }, {});

  return (
    <PagesContext.Provider value={{ pages }}>{children}</PagesContext.Provider>
  );
};
