import "./src/styles/global.css";
import React from "react";
import { LanguageProvider } from "./src/components/context/language-context";
import { PagesProvider } from "./src/components/context/pages-context";

export const wrapRootElement = ({ element }) => (
  <PagesProvider>{element}</PagesProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <LanguageProvider {...props}>{element}</LanguageProvider>
);
